.header {
  background-color: #f2f2f2;
  padding: 3rem 2rem;
  font-family: 'Inter', sans-serif;
  min-height: 40vh;
  display: flex;
  align-items: center;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 2rem 0;
}

.hero-text {
  flex: 1 1 55%;
  padding-right: 2rem;
  text-align: left;
}

.hero-text .location {
  font-size: 0.9rem;
  color: #999;
  margin-bottom: 0.5rem;
}

.hero-text h1 {
  font-size: 2.8rem;
  line-height: 1.2;
  font-weight: 700;
  color: #111;
  margin-bottom: 0.75rem;
}

.bold-blue {
  color: #5BF58B;
}

.subtext {
  font-size: 1rem;
  margin: 1rem 0 2rem;
  color: #444;
}

.cta-buttons {
  display: flex;
  gap: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 3px 3px 0 #000;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.btn:hover {
  transform: scale(1.03);
}

.btn.dark {
  background-color: #000;
  color: #fff;
}

.btn.light {
  background-color: #5BF58B;
  color: #000;
}

.hero-image {
  flex: 1 1 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  width: 100%;
  max-width: 350px;
  border-radius: 8px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 1rem 0;
  }

  .hero-text {
    flex: 1 1 100%;
    padding-right: 0;
    margin-bottom: 2rem;
    text-align: center;
  }

  .hero-image {
    flex: 1 1 100%;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  .hero-image img {
    max-width: 280px;
  }

  .cta-buttons {
    justify-content: center;
    margin-top: 1.5rem;
  }
}
